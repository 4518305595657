import store from '../../store'
import { UnitOfMeasure } from '@fusion/units'
import { Measurement_Names } from '../../store/devices/utils'
import {
  measurementTypesKey,
  measurementTypesUnitsKey,
  measurementFriendlyNameKey,
  measurementImgSrc,
  measurementTypes,
  allMeasurementStatistics,
  measurementTypesOrder,
  measurementTypesValueRange,
  measurementTypesLabelKey,
  measurementPermissions,
} from '../../services/device-measurements'
import { getPermissionsByDeviceId } from '../../services/subscriptions'

import { getReadingValue } from '../../components/devicePopover/utils'

const getSelectedParameterFromConfiguration = (
  currentMeasurement,
  configuration
) => {
  if (configuration) {
    if (typeof configuration.savedParameter === 'number') {
      return configuration.savedParameter
    }

    if (typeof configuration.defaultParameter === 'number') {
      return configuration.defaultParameter
    }
  }

  return currentMeasurement
}

export const getPopoverMeasurementData = (
  translateFn,
  measurementId,
  readings,
  configuration
) => {
  const key =
    Measurement_Names[measurementTypesKey[measurementId]] ||
    measurementTypesKey[measurementId]

  return {
    key,
    unitsLabel: translateFn(measurementTypesUnitsKey[measurementId]),
    label: translateFn(measurementFriendlyNameKey[measurementId]),
    imageSrc: measurementImgSrc[measurementId],
    reading: key ? getReadingValue(readings, key) : null,
    ...configuration,
  }
}

export const getWidgetSelectedDevices = (deviceIds, accountId) => {
  if (deviceIds?.length) {
    return deviceIds.map((deviceId) => {
      return {
        id: deviceId,
        friendlyName: store.getters['devices/getNameByDeviceId'](deviceId),
        model: store.getters['devices/getModelByDeviceId'](deviceId),
        submodel: store.getters['devices/getSubmodelByDeviceId'](deviceId),
        isMine:
          accountId === store.getters['devices/getAccountByDeviceId'](deviceId),
        isShared: store.getters['devices/getIsSharedByDeviceId'](deviceId),
      }
    })
  }

  return []
}

export const getWidgetAvailableMeasurements = (
  selectedDevices,
  filterMeasurements,
  filterMeasurementStatistics
) => {
  // Build an array of unique measurements across all selected devices
  let measurements = [
    ...new Set(
      selectedDevices
        .map((device) => {
          const isPublicOnly = !device.isMine && !device.isShared
          return store.getters['devicemodels/getSupportedMeasurements'](
            device.model,
            device.submodel,
            isPublicOnly
          )
        })
        .flat(1)
    ),
  ]

  // Filter measurements based on whether PM 2.5 or PM 10 is selected
  if (filterMeasurements) {
    measurements = measurements.filter((measurement) => {
      if (store.getters['map/getMeasurementType'] === measurementTypes.pm25) {
        return (
          measurement !== measurementTypes.pm10 &&
          measurement !== measurementTypes.pm10_raw
        )
      } else {
        return (
          measurement !== measurementTypes.pm25 &&
          measurement !== measurementTypes.pm25_raw
        )
      }
    })
  }

  if (filterMeasurementStatistics) {
    measurements = measurements.filter((measurement) => {
      return allMeasurementStatistics.indexOf(measurement) === -1
    })
  }

  // Sort the array by the defined sort array
  return measurements.sort(function (a, b) {
    return measurementTypesOrder.indexOf(a) - measurementTypesOrder.indexOf(b)
  })
}

export const getWidgetMeasurementOptions = (
  translateFn,
  availableMeasurements,
  permissionSet
) => {
  if (availableMeasurements?.length) {
    return availableMeasurements.map((measurement) => {
      const result = {
        value: measurement,
        label: translateFn(
          `map.historyDialog.dropdown.${measurementTypesLabelKey[measurement]}`
        ),
        valueRange: measurementTypesValueRange[measurement],
        units: measurementTypesUnitsKey[measurement]
          ? translateFn(measurementTypesUnitsKey[measurement])
          : '',
      }
      if (measurement === measurementTypes.temp) {
        result.units =
          store.state.userProfile?.settings?.tempUnit ===
          UnitOfMeasure.DEGREES_F
            ? translateFn('units.fahrenheit')
            : translateFn('units.celsius')
      }
      result.disabled = !isMeasurementAllowed(measurement, permissionSet)
      return result
    })
  }

  return []
}

export const getDevicesPermissionSet = (selectedDevices) => {
  if (selectedDevices?.length) {
    let perms = {}

    for (const device of selectedDevices) {
      const deviceId = device.id
      perms[deviceId] = getPermissionsByDeviceId(deviceId)
    }

    return [
      ...new Set(selectedDevices.map((device) => perms[device.id]).flat(1)),
    ]
  }

  return []
}

export const getSelectedMeasurement = (
  currentMeasurement,
  availableMeasurements,
  permissionSet,
  configuration
) => {
  let newSelectedMeasurement = getSelectedParameterFromConfiguration(
    currentMeasurement,
    configuration
  )

  if (availableMeasurements?.length && permissionSet) {
    if (
      !isMeasurementAllowed(newSelectedMeasurement, permissionSet) ||
      !availableMeasurements.includes(newSelectedMeasurement)
    ) {
      for (const availableMeasurement of availableMeasurements) {
        if (isMeasurementAllowed(availableMeasurement, permissionSet)) {
          return availableMeasurement
        }
      }
    }
  }

  return newSelectedMeasurement
}

export const isMeasurementAllowed = (measurement, permissionSet) => {
  const slug = measurementPermissions[measurement]
  if (slug) {
    return permissionSet.includes(slug)
  }
  return true
}
