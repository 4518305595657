<template>
  <component
    :v-if="componentName"
    :is="componentName"
    :widgetId="widgetId"
    :widgetType="widgetType"
    :widgetMaxDevices="widgetMaxDevices"
    :deviceIds="deviceIds"
    :width="width"
    :height="height"
    :settings="settings"
    @removeWidget="removeWidget"
    @refreshDashboard="refreshDashboard"
  />
</template>

<script>
import PopoverWidget from './content/PopoverWidget.vue'
import ContinuousMonitoringWidget from './content/ContinuousMonitoringWidget.vue'

export default {
  name: 'CurrentReadingsWidget',
  components: {
    PopoverWidget,
    ContinuousMonitoringWidget,
  },
  props: {
    widgetId: String,
    widgetType: String,
    widgetMaxDevices: Object,
    deviceIds: {
      type: Array,
      default() {
        return []
      }
    },
    width: Number,
    height: Number,
    settings: Object,
  },
  computed: {
    deviceId() {
      return this.deviceIds?.[0] || ''
    },
    devicesLength() {
      return Object.entries(this.$store.state.devices.allDevices).length
    },
    isDeviceConnectedToSmartBridge() {
      return !!this.$store.getters[
        'devices/getConnectedSmartBridgeIdByDeviceId'
      ](this.deviceId)
    },
    componentName() {
      if (this.devicesLength) {
        return this.isDeviceConnectedToSmartBridge
          ? 'ContinuousMonitoringWidget'
          : 'PopoverWidget'
      }

      return ''
    },
  },
  methods: {
    removeWidget(options) {
      this.$emit('removeWidget', options)
    },
    refreshDashboard() {
      this.$emit('refreshDashboard')
    },
  },
}
</script>
