<template>
  <base-widget
    :deviceIds="deviceIds"
    :widgetId="widgetId"
    :widgetType="widgetType"
    :widgetMaxDevices="widgetMaxDevices"
    :title="name"
    :subtitle="deviceType"
    :class="[sizeClass]"
    :fullscreen="fullscreen"
    :observeSize="true"
    @removeDevice="removeDevice"
    @removeWidget="removeWidget"
    @widgetSizeChanged="updateWidgetSize"
    @refreshDashboard="refreshDashboard"
    data-cy="currentReadingsWidget"
  >
    <popover-widget-toolbar slot="toolbar" :deviceId="deviceId" />
    <div slot="left-toolbar" class="toolbar">
      <v-btn text @click="refresh" data-cy="refreshPopover">
        <v-icon color="grey">mdi-refresh</v-icon>
      </v-btn>
    </div>
    <popover-main-content
      slot="content"
      :deviceId="deviceId"
      :permissions="permissions"
      ref="popoverMain"
    />
  </base-widget>
</template>

<script>
import BaseWidget from '../../BaseWidget.vue'
import PopoverToolbar from '../../../devicePopover/content/title/popoverToolbar.vue'
import popoverMainContent from '../../../devicePopover/content/popoverMainContent.vue'
import { widgetSettings } from '../../../../services/dashboard'
import { deviceModelDisplay } from '../../../../services/map'
import { getPermissionsByDeviceId } from '../../../../services/subscriptions'

const BREAKPOINT_WIDTH_XL = 700
const BREAKPOINT_WIDTH_LG = 600
const BREAKPOINT_WIDTH_MD = 500
const BREAKPOINT_WIDTH_SM = 400
const BREAKPOINT_WIDTH_XS = 340
const BREAKPOINT_WIDTH_XXS = 280

export default {
  components: {
    'base-widget': BaseWidget,
    'popover-widget-toolbar': PopoverToolbar,
    'popover-main-content': popoverMainContent,
  },
  mixins: [widgetSettings],
  props: {
    widgetId: String,
    widgetType: String,
    widgetMaxDevices: Object,
    deviceIds: {
      type: Array,
      default() {
        return []
      }
    },
    width: Number,
    height: Number,
    settings: Object,
  },
  data() {
    return {
      fullscreen: false,
      dimensions: {
        width: 0,
        height: 0,
      },
    }
  },
  computed: {
    deviceId() {
      return this.deviceIds?.[0]
    },
    name: function () {
      return this.$store.getters['devices/getNameByDeviceId'](this.deviceId)
    },
    deviceModel() {
      return this.$store.getters['devices/getModelByDeviceId'](this.deviceId)
    },
    deviceType: function () {
      const modelKey = this.$store.getters['devicemodels/getModelKey'](
        this.deviceModel
      )
      return deviceModelDisplay(modelKey, this.$t.bind(this))
    },
    sizeClass() {
      let className = ''
      if (this.dimensions.width <= BREAKPOINT_WIDTH_XL) {
        className = 'widget-xl'
      }
      if (this.dimensions.width <= BREAKPOINT_WIDTH_LG) {
        className = 'widget-lg'
      }
      if (this.dimensions.width <= BREAKPOINT_WIDTH_MD) {
        className = 'widget-md'
      }
      if (this.dimensions.width <= BREAKPOINT_WIDTH_SM) {
        className = 'widget-sm'
      }
      if (this.dimensions.width <= BREAKPOINT_WIDTH_XS) {
        className = 'widget-xs'
      }
      if (this.dimensions.width <= BREAKPOINT_WIDTH_XXS) {
        className = 'widget-xxs'
      }
      return className
    },
    permissions: function () {
      return getPermissionsByDeviceId(this.deviceId)
    },
  },
  methods: {
    removeDevice(index, options) {
      this.removeWidget(options)
    },
    removeWidget(options) {
      this.$emit('removeWidget', options)
    },
    refreshDashboard() {
      this.$emit('refreshDashboard')
    },
    refresh() {
      this.$refs.popoverMain.refresh()
    },
    updateWidgetSize(dims) {
      this.dimensions = dims
    },
  },
}
</script>
<style lang="scss" scoped>
.toolbar {
  padding-left: 6px;
  .v-btn {
    min-width: 10px !important;
    padding: 0 0.4rem !important;
  }
}
</style>
<style lang="scss">
.widget-xxs,
.widget-xs {
  .widget-content-container {
    padding: 4px;
  }
}
</style>
