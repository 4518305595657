<template>
  <measurement-list :measurements="readingStatistics" />
</template>

<script>
import MeasurementList from '@/components/devicePopover/content/measurements/layouts/MeasurementList.vue'
import { measurementStatistics } from '@/services/device-measurements'
import { getPopoverMeasurementData } from '@/helpers/widgets/helper'
import { convertArrayToObject } from '@/services/data-conversion'

export default {
  name: 'MeasurementStatistics',
  components: {
    'measurement-list': MeasurementList,
  },
  props: {
    deviceId: { type: String, default: '' },
    selectedParameter: Number,
    measurementStatisticsReadings: Object,
  },
  computed: {
    readings() {
      return (
        this.measurementStatisticsReadings ||
        convertArrayToObject(
          this.$store.getters['devices/getSensorReadingsByDeviceId'](
            this.deviceId
          ),
          'name'
        )
      )
    },
    readingStatistics() {
      const statistics = measurementStatistics[this.selectedParameter]

      if (statistics) {
        return [
          this.getPopoverMeasurementStatistic(
            statistics.minimum,
            this.$t('widget.currentReadings.minimum')
          ),
          this.getPopoverMeasurementStatistic(
            statistics.maximum,
            this.$t('widget.currentReadings.maximum')
          ),
          this.getPopoverMeasurementStatistic(
            statistics.average,
            this.$t('widget.currentReadings.average')
          ),
          this.getPopoverMeasurementStatistic(
            statistics.twa,
            this.$t('widget.currentReadings.twa')
          ),
        ]
      }

      return []
    },
  },
  methods: {
    getPopoverMeasurementStatistic(measurementId, label) {
      return getPopoverMeasurementData(
        this.$t.bind(this),
        measurementId,
        this.readings,
        {
          label,
          customClass: 'small-size bolded-reading grey-empty-reading',
        }
      )
    },
  },
}
</script>
