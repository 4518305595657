<template>
  <div class="loading-container">
    {{ this.$t('map.popover.noDataAvailable') }}
  </div>
</template>

<script>
export default {
  name: 'LoadingContainer',
}
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 400px;
}
</style>
