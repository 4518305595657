import { SlugsEnum } from '../permissions/SlugsEnum'

const SENSOR_TYPES = {
  PM: 'pm',
  TEMP_RH: 'temp_rh',
  BARO: 'baro',
  GAS_CO: 'gas_co',
  GAS_CO2: 'gas_co2',
  GAS_O3: 'gas_o3',
  GAS_VOC: 'gas_voc',
  GAS_SO2: 'gas_so2',
  GAS_NO2: 'gas_no2',
  GAS_CH2O: 'gas_ch2o',
}

const MEASUREMENT_IMG_SRC = {
  PM: '/img/measurements/pm.svg',
  TEMP: '/img/measurements/temp.svg',
  RH: '/img/measurements/rh.svg',
  BP: '/img/measurements/bp.svg',
  CO: '/img/measurements/co.svg',
  CO2: '/img/measurements/co2.svg',
  O3: '/img/measurements/o3.svg',
  VOC: '/img/measurements/voc.svg',
  SO2: '/img/measurements/so2.svg',
  NO2: '/img/measurements/no2.svg',
  CH2O: '/img/measurements/ch2o.svg',
  MINIMUM: '/img/measurements/minimum.svg',
  MAXIMUM: '/img/measurements/maximum.svg',
  AVERAGE: '/img/measurements/average.svg',
  TWA: '/img/measurements/twa.svg',
}

const MEASUREMENT_FRIENDLY_NAME_PATH = 'map.historyDialog.dropdown'

export const measurementTypes = Object.freeze({
  pm25: 0,
  pm10: 1,
  temp: 2,
  rh: 3,
  pm25_raw: 4,
  pm10_raw: 5,
  baro_inhg: 6,
  co: 7,
  co2: 8,
  o3: 9,
  tvoc: 10,
  so2: 11,
  no2: 12,
  ch2o: 13,
  pm1x0: 14,
  pm4x0: 15,
  pm_total: 16,
  pm1x0_min: 17,
  pm1x0_max: 18,
  pm1x0_avg: 19,
  pm1x0_twa: 20,
  pm2x5_min: 21,
  pm2x5_max: 22,
  pm2x5_avg: 23,
  pm2x5_twa: 24,
  pm4x0_min: 25,
  pm4x0_max: 26,
  pm4x0_avg: 27,
  pm4x0_twa: 28,
  pm10_min: 29,
  pm10_max: 30,
  pm10_avg: 31,
  pm10_twa: 32,
  pm_total_min: 33,
  pm_total_max: 34,
  pm_total_avg: 35,
  pm_total_twa: 36,
})

export const measurementAlertPermissions = Object.freeze({
  pm25: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  pm10: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  temp: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  rh: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  pm25_raw: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  pm10_raw: SlugsEnum.AlertSetupCreateAlertPM10_PM25_Temp_RH,
  co: SlugsEnum.AlertSetupCreateAlertCO,
  o3: SlugsEnum.AlertSetupCreateAlertO3,
  tvoc: SlugsEnum.AlertSetupCreateAlertTVOC,
  so2: SlugsEnum.AlertSetupCreateAlertSO2,
  no2: SlugsEnum.AlertSetupCreateAlertNO2,
  ch2o: SlugsEnum.AlertSetupCreateAlertCH2O,
})

export const measurementPermissions = Object.freeze({
  0: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  1: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  2: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  3: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  4: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  5: SlugsEnum.GasReadPM10_PM25_Temp_RH,
  7: SlugsEnum.GasReadCO,
  9: SlugsEnum.GasReadO3,
  10: SlugsEnum.GasReadTVOC,
  11: SlugsEnum.GasReadSO2,
  12: SlugsEnum.GasReadNO2,
  13: SlugsEnum.GasReadCH2O,
})

export const measurementTypesKey = Object.freeze({
  0: 'mcpm2x5_aqi',
  1: 'mcpm10_aqi',
  2: 'temp_c',
  3: 'rh_percent',
  4: 'mcpm2x5',
  5: 'mcpm10',
  6: 'baro_inhg',
  7: 'co_ppm',
  8: 'co2_ppm',
  9: 'o3_ppb',
  10: 'voc_mgm3',
  11: 'so2_ppb',
  12: 'no2_ppb',
  13: 'ch2o_ppb',
  14: 'mcpm1x0',
  15: 'mcpm4x0',
  16: 'mcpm_total',
  17: 'mcpm1x0_min',
  18: 'mcpm1x0_max',
  19: 'mcpm1x0_avg',
  20: 'mcpm1x0_twa',
  21: 'mcpm2x5_min',
  22: 'mcpm2x5_max',
  23: 'mcpm2x5_avg',
  24: 'mcpm2x5_twa',
  25: 'mcpm4x0_min',
  26: 'mcpm4x0_max',
  27: 'mcpm4x0_avg',
  28: 'mcpm4x0_twa',
  29: 'mcpm10_min',
  30: 'mcpm10_max',
  31: 'mcpm10_avg',
  32: 'mcpm10_twa',
  33: 'mcpm_total_min',
  34: 'mcpm_total_max',
  35: 'mcpm_total_avg',
  36: 'mcpm_total_twa',
})

export const sensorTypesKey = Object.freeze({
  0: SENSOR_TYPES.PM,
  1: SENSOR_TYPES.PM,
  2: SENSOR_TYPES.TEMP_RH,
  3: SENSOR_TYPES.TEMP_RH,
  4: SENSOR_TYPES.PM,
  5: SENSOR_TYPES.PM,
  6: SENSOR_TYPES.BARO,
  7: SENSOR_TYPES.GAS_CO,
  8: SENSOR_TYPES.GAS_CO2,
  9: SENSOR_TYPES.GAS_O3,
  10: SENSOR_TYPES.GAS_VOC,
  11: SENSOR_TYPES.GAS_SO2,
  12: SENSOR_TYPES.GAS_NO2,
  13: SENSOR_TYPES.GAS_CH2O,
  14: SENSOR_TYPES.PM,
  15: SENSOR_TYPES.PM,
  16: SENSOR_TYPES.PM,
  17: SENSOR_TYPES.PM,
  18: SENSOR_TYPES.PM,
  19: SENSOR_TYPES.PM,
  20: SENSOR_TYPES.PM,
  21: SENSOR_TYPES.PM,
  22: SENSOR_TYPES.PM,
  23: SENSOR_TYPES.PM,
  24: SENSOR_TYPES.PM,
  25: SENSOR_TYPES.PM,
  26: SENSOR_TYPES.PM,
  27: SENSOR_TYPES.PM,
  28: SENSOR_TYPES.PM,
  29: SENSOR_TYPES.PM,
  30: SENSOR_TYPES.PM,
  31: SENSOR_TYPES.PM,
  32: SENSOR_TYPES.PM,
  33: SENSOR_TYPES.PM,
  34: SENSOR_TYPES.PM,
  35: SENSOR_TYPES.PM,
  36: SENSOR_TYPES.PM,
})

export const measurementTypesLabelKey = Object.freeze({
  0: 'aqi25',
  1: 'aqi10',
  2: 'temperature',
  3: 'humidity',
  4: 'pm25',
  5: 'pm10',
  6: 'pressure',
  7: 'co',
  8: 'co2',
  9: 'o3',
  10: 'tvoc',
  11: 'so2',
  12: 'no2',
  13: 'ch2o',
  14: 'pm1x0',
  15: 'pm4x0',
  16: 'pm_total',
  17: 'pm1x0_min',
  18: 'pm1x0_max',
  19: 'pm1x0_avg',
  20: 'pm1x0_twa',
  21: 'pm2x5_min',
  22: 'pm2x5_max',
  23: 'pm2x5_avg',
  24: 'pm2x5_twa',
  25: 'pm4x0_min',
  26: 'pm4x0_max',
  27: 'pm4x0_avg',
  28: 'pm4x0_twa',
  29: 'pm10_min',
  30: 'pm10_max',
  31: 'pm10_avg',
  32: 'pm10_twa',
  33: 'pm_total_min',
  34: 'pm_total_max',
  35: 'pm_total_avg',
  36: 'pm_total_twa',
})

export const measurementFriendlyNameKey = Object.freeze({
  0: `${MEASUREMENT_FRIENDLY_NAME_PATH}.aqi25`,
  1: `${MEASUREMENT_FRIENDLY_NAME_PATH}.aqi10`,
  2: `${MEASUREMENT_FRIENDLY_NAME_PATH}.temperature`,
  3: `${MEASUREMENT_FRIENDLY_NAME_PATH}.humidity`,
  4: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm25`,
  5: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm10`,
  6: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pressure`,
  7: `${MEASUREMENT_FRIENDLY_NAME_PATH}.co`,
  8: `${MEASUREMENT_FRIENDLY_NAME_PATH}.co2`,
  9: `${MEASUREMENT_FRIENDLY_NAME_PATH}.o3`,
  10: `${MEASUREMENT_FRIENDLY_NAME_PATH}.tvoc`,
  11: `${MEASUREMENT_FRIENDLY_NAME_PATH}.so2`,
  12: `${MEASUREMENT_FRIENDLY_NAME_PATH}.no2`,
  13: `${MEASUREMENT_FRIENDLY_NAME_PATH}.ch2o`,
  14: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm1x0`,
  15: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm4x0`,
  16: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm_total`,
  17: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm1x0_min`,
  18: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm1x0_max`,
  19: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm1x0_avg`,
  20: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm1x0_twa`,
  21: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm2x5_min`,
  22: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm2x5_max`,
  23: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm2x5_avg`,
  24: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm2x5_twa`,
  25: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm4x0_min`,
  26: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm4x0_max`,
  27: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm4x0_avg`,
  28: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm4x0_twa`,
  29: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm10_min`,
  30: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm10_max`,
  31: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm10_avg`,
  32: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm10_twa`,
  33: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm_total_min`,
  34: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm_total_max`,
  35: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm_total_avg`,
  36: `${MEASUREMENT_FRIENDLY_NAME_PATH}.pm_total_twa`,
})

export const measurementTypesValueRange = Object.freeze({
  0: [0, null],
  1: [0, null],
  2: [null, null],
  3: [null, null],
  4: [0, null],
  5: [0, null],
  6: [null, null],
  7: [null, null],
  8: [null, null],
  9: [null, null],
  10: [null, null],
  11: [null, null],
  12: [null, null],
  13: [null, null],
  14: [null, null],
  15: [null, null],
  16: [null, null],
  17: [null, null],
  18: [null, null],
  19: [null, null],
  20: [null, null],
  21: [null, null],
  22: [null, null],
  23: [null, null],
  24: [null, null],
  25: [null, null],
  26: [null, null],
  27: [null, null],
  28: [null, null],
  29: [null, null],
  30: [null, null],
  31: [null, null],
  32: [null, null],
  33: [null, null],
  34: [null, null],
  35: [null, null],
  36: [null, null],
})

export const measurementTypesUnitsKey = Object.freeze({
  0: undefined,
  1: undefined,
  2: undefined,
  3: 'units.percent',
  4: undefined,
  5: undefined,
  6: 'units.inchMercury',
  7: 'units.partsPerMillion',
  8: 'units.partsPerMillion',
  9: 'units.partsPerBillion',
  10: 'units.mgm3',
  11: 'units.partsPerBillion',
  12: 'units.partsPerBillion',
  13: 'units.partsPerBillion',
  14: undefined,
  15: undefined,
  16: undefined,
  17: undefined,
  18: undefined,
  19: undefined,
  20: undefined,
  21: undefined,
  22: undefined,
  23: undefined,
  24: undefined,
  25: undefined,
  26: undefined,
  27: undefined,
  28: undefined,
  29: undefined,
  30: undefined,
  31: undefined,
  32: undefined,
  33: undefined,
  34: undefined,
  35: undefined,
  36: undefined,
})

export const measurementImgSrc = Object.freeze({
  0: MEASUREMENT_IMG_SRC.PM,
  1: MEASUREMENT_IMG_SRC.PM,
  2: MEASUREMENT_IMG_SRC.TEMP,
  3: MEASUREMENT_IMG_SRC.RH,
  4: MEASUREMENT_IMG_SRC.PM,
  5: MEASUREMENT_IMG_SRC.PM,
  6: MEASUREMENT_IMG_SRC.BP,
  7: MEASUREMENT_IMG_SRC.CO,
  8: MEASUREMENT_IMG_SRC.CO2,
  9: MEASUREMENT_IMG_SRC.O3,
  10: MEASUREMENT_IMG_SRC.VOC,
  11: MEASUREMENT_IMG_SRC.SO2,
  12: MEASUREMENT_IMG_SRC.NO2,
  13: MEASUREMENT_IMG_SRC.CH2O,
  14: MEASUREMENT_IMG_SRC.PM,
  15: MEASUREMENT_IMG_SRC.PM,
  16: MEASUREMENT_IMG_SRC.PM,
  17: MEASUREMENT_IMG_SRC.MINIMUM,
  18: MEASUREMENT_IMG_SRC.MAXIMUM,
  19: MEASUREMENT_IMG_SRC.AVERAGE,
  20: MEASUREMENT_IMG_SRC.TWA,
  21: MEASUREMENT_IMG_SRC.MINIMUM,
  22: MEASUREMENT_IMG_SRC.MAXIMUM,
  23: MEASUREMENT_IMG_SRC.AVERAGE,
  24: MEASUREMENT_IMG_SRC.TWA,
  25: MEASUREMENT_IMG_SRC.MINIMUM,
  26: MEASUREMENT_IMG_SRC.MAXIMUM,
  27: MEASUREMENT_IMG_SRC.AVERAGE,
  28: MEASUREMENT_IMG_SRC.TWA,
  29: MEASUREMENT_IMG_SRC.MINIMUM,
  30: MEASUREMENT_IMG_SRC.MAXIMUM,
  31: MEASUREMENT_IMG_SRC.AVERAGE,
  32: MEASUREMENT_IMG_SRC.TWA,
  33: MEASUREMENT_IMG_SRC.MINIMUM,
  34: MEASUREMENT_IMG_SRC.MAXIMUM,
  35: MEASUREMENT_IMG_SRC.AVERAGE,
  36: MEASUREMENT_IMG_SRC.TWA,
})

export const measurementTypesOrder = Object.freeze([
  measurementTypes.pm1x0,
  measurementTypes.pm25,
  measurementTypes.pm25_raw,
  measurementTypes.pm4x0,
  measurementTypes.pm10,
  measurementTypes.pm10_raw,
  measurementTypes.pm_total,
  measurementTypes.pm1x0_min,
  measurementTypes.pm1x0_max,
  measurementTypes.pm1x0_avg,
  measurementTypes.pm1x0_twa,
  measurementTypes.pm2x5_min,
  measurementTypes.pm2x5_max,
  measurementTypes.pm2x5_avg,
  measurementTypes.pm2x5_twa,
  measurementTypes.pm4x0_min,
  measurementTypes.pm4x0_max,
  measurementTypes.pm4x0_avg,
  measurementTypes.pm4x0_twa,
  measurementTypes.pm10_min,
  measurementTypes.pm10_max,
  measurementTypes.pm10_avg,
  measurementTypes.pm10_twa,
  measurementTypes.pm_total_min,
  measurementTypes.pm_total_max,
  measurementTypes.pm_total_avg,
  measurementTypes.pm_total_twa,
  measurementTypes.rh,
  measurementTypes.temp,
  measurementTypes.baro_inhg,
  measurementTypes.co,
  measurementTypes.co2,
  measurementTypes.o3,
  measurementTypes.tvoc,
  measurementTypes.so2,
  measurementTypes.no2,
  measurementTypes.ch2o,
])

export const publicMeasurementPermissions = Object.freeze([
  SlugsEnum.GasReadPM10_PM25_Temp_RH,
])

export const allMeasurementStatistics = Object.freeze([
  measurementTypes.pm1x0_min,
  measurementTypes.pm1x0_max,
  measurementTypes.pm1x0_avg,
  measurementTypes.pm1x0_twa,
  measurementTypes.pm2x5_min,
  measurementTypes.pm2x5_max,
  measurementTypes.pm2x5_avg,
  measurementTypes.pm2x5_twa,
  measurementTypes.pm4x0_min,
  measurementTypes.pm4x0_max,
  measurementTypes.pm4x0_avg,
  measurementTypes.pm4x0_twa,
  measurementTypes.pm10_min,
  measurementTypes.pm10_max,
  measurementTypes.pm10_avg,
  measurementTypes.pm10_twa,
  measurementTypes.pm_total_min,
  measurementTypes.pm_total_max,
  measurementTypes.pm_total_avg,
  measurementTypes.pm_total_twa,
])

export const measurementStatistics = Object.freeze({
  0: null,
  1: null,
  2: null,
  3: null,
  4: {
    minimum: 21,
    maximum: 22,
    average: 23,
    twa: 24,
  },
  5: {
    minimum: 29,
    maximum: 30,
    average: 31,
    twa: 32,
  },
  6: null,
  7: null,
  8: null,
  9: null,
  10: null,
  11: null,
  12: null,
  13: null,
  14: {
    minimum: 17,
    maximum: 18,
    average: 19,
    twa: 20,
  },
  15: {
    minimum: 25,
    maximum: 26,
    average: 27,
    twa: 28,
  },
  16: {
    minimum: 33,
    maximum: 34,
    average: 35,
    twa: 36,
  },
  17: null,
  18: null,
  19: null,
  20: null,
  21: null,
  22: null,
  23: null,
  24: null,
  25: null,
  26: null,
  27: null,
  28: null,
  29: null,
  30: null,
  31: null,
  32: null,
  33: null,
  34: null,
  35: null,
  36: null,
})
