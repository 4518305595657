<template>
  <div :class="{ 'study-container': !!latestDeviceStudy }">
    <div v-if="!isLoading && !!latestDeviceStudy">
      <div class="study-details" v-if="!!latestDeviceStudy">
        <popover-timestamp
          :timestamp="latestDeviceStudy.min_timestamp"
          :label="$t('widget.currentReadings.studyStart')"
          customClass="small-subtitle"
          dataCy="studyStart"
        />
        <popover-timestamp
          :timestamp="latestDeviceStudy.max_timestamp"
          :label="$t('widget.currentReadings.studyStop')"
          customClass="small-subtitle"
          dataCy="studyStop"
        />
      </div>
      <popover-last-telemetry-timestamp v-else :deviceId="deviceId" />
      <one-measurement-monitoring
        :deviceId="deviceId"
        :selectedParameter="selectedParameter"
        :size="latestDeviceStudy ? 'medium' : 'large'"
      />
      <measurement-statistics
        v-if="latestDeviceStudy"
        :deviceId="deviceId"
        :measurementStatisticsReadings="measurementStatisticsReadings"
        :selectedParameter="selectedParameter"
      />
    </div>
    <no-data-container v-else-if="!isLoading"/>
    <loading-container v-else />
  </div>
</template>
<script>

  import LoadingContainer from '@/components/devicePopover/layouts/LoadingContainer.vue'
  import MeasurementStatistics from '@/components/widgets/currentReadings/content/MeasurementStatistics.vue'
  import NoDataContainer from '@/components/devicePopover/layouts/NoDataContainer.vue'
  import OneMeasurementMonitoring from '@/components/devicePopover/layouts/OneMeasurementMonitoring.vue'
  import popoverLastTelemetryTimestamp from '@/components/devicePopover/content/header/popoverLastTelemetryTimestamp.vue'
  import popoverTimestamp from '@/components/devicePopover/content/popoverTimestamp.vue'
  import { getStatisticsReadingsFromStudyStates } from '@/helpers/studies/statisticsHelper'

  export default {
    name: 'CurrentReadings',
    components: {
      'loading-container': LoadingContainer,
      'measurement-statistics': MeasurementStatistics,
      'no-data-container': NoDataContainer,
      'one-measurement-monitoring': OneMeasurementMonitoring,
      'popover-timestamp': popoverTimestamp,
      'popover-last-telemetry-timestamp': popoverLastTelemetryTimestamp,
    },
    props: {
      deviceId: String,
      isLoading: Boolean,
      latestDeviceStudy: Object,
      selectedParameter: Number,
    },
    computed: {
      measurementStatisticsReadings() {
        const lastContact = this.$store.getters['devices/getLastContactByDeviceId'](this.deviceId)
        return getStatisticsReadingsFromStudyStates(this.latestDeviceStudy?.states, lastContact)
      },
    }
  }
</script>

<style lang="scss" scoped>
  .study-details {
    display: flex;
    justify-content: space-between;
  }
</style>
