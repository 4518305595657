<template>
  <v-dialog
    @input="close"
    v-model="open"
    :hideOverlay="false"
    :persistent="true"
    data-cy="currentReadingsDialog"
    max-width="500"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" v-bind="on"></slot>
    </template>
    <v-card
      :class="{'disconnected-device-theme': !isDeviceConnected,}"
    >
      <v-card-title class="widget-header-container">
        <span>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                class="black--text"
                text
                v-on="on"
                data-cy="historyDialogDropdown"
                v-on:focus.native="$event.target.blur()"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <measurement-options
              :deviceIds="[currentDeviceId]"
              :passedinParameter="selectedParameter"
              :filterMeasurementStatistics="true"
              @parameterChange="parameterChange"
            />
          </v-menu>
        </span>
        <span class="widget-title-container">
          <v-tooltip bottom :max-width="600" :disabled="!isNameOverflowing">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="current-readings-title" ref="deviceName">
                <span>{{ name }}</span>
              </div>
            </template>
            <span>{{ name }}</span>
          </v-tooltip>
          <div v-if="deviceType" class="widget-subtitle">
            <span>{{ deviceType }}</span
            >&nbsp;
            <span v-if="serial" class="device-serial">{{ serial }}</span>
          </div>
        </span>
        <span>
          <v-btn text @click="refresh" data-cy="refreshHistory">
            <v-icon color="grey">mdi-refresh</v-icon>
          </v-btn>
        </span>
        <v-spacer></v-spacer>
        <popover-toolbar :deviceId="currentDeviceId" />
        <v-spacer></v-spacer>
        <span>
            <v-btn @click="close" text data-cy="closeHistoryDialog">
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
        </span>
      </v-card-title>
      <v-card-text class="readings-dialog-content-container">
        <current-readings
          :deviceId="currentDeviceId"
          :isLoading="isLoading"
          :selectedParameter="selectedParameter"
          :latestDeviceStudy="latestDeviceStudy"
        />
      </v-card-text>
      <hr class="divider" />
      <v-card-text class="widget-footer-container">
        <div>
          <popover-last-calibration-timestamp :deviceId="currentDeviceId"/>
        </div>
        <div>
          <popover-current-calibration-factor :deviceId="currentDeviceId"/>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CurrentReadings from '@/components/devicePopover/layouts/CurrentReadings.vue'
import { deviceModelDisplay } from '@/services/map'
import { isChildOverflowing } from '@/services/html-utilities'
import { mapActions } from 'vuex'
import MeasurementOptions from '@/components/devicePopover/content/dropdown/MeasurementOptions.vue'
import measurementOptionsMixin from '@/mixins/widgets/measurementOptionsMixin'
import { measurementTypes } from '@/services/device-measurements'
import { getLatestStudy } from '@/services/device-metadata'
import popoverLastCalibrationTimestamp from '@/components/devicePopover/content/footer/popoverLastCalibrationTimestamp.vue'
import popoverCurrentCalibrationFactor from '@/components/devicePopover/content/footer/popoverCurrentCalibrationFactor.vue'
import PopoverToolbar from '@/components/devicePopover/content/title/popoverToolbar.vue'
import { getUserAccountId } from '@/helpers/loginas/logInAsHelper'

export default {
  name: 'DeviceSharingDialog',
  props: {
    open: Boolean,
    currentDeviceId: String,
  },
  data() {
    return {
      accountId: null,
      isLoading: false,
      isNameOverflowing: false,
      latestDeviceStudy: null,
      defaultParameter: measurementTypes.pm25_raw,
      filterMeasurementStatistics: true
    }
  },
  mixins: [measurementOptionsMixin],
  components: {
    'current-readings': CurrentReadings,
    'measurement-options': MeasurementOptions,
    'popover-last-calibration-timestamp': popoverLastCalibrationTimestamp,
    'popover-current-calibration-factor': popoverCurrentCalibrationFactor,
    'popover-toolbar': PopoverToolbar,
  },
  async mounted() {
    this.accountId = await getUserAccountId(this.$auth)
    await this.refresh()
  },
  watch: {
    async open() {
      if (this.open) {
        await this.refresh()
      }
    },
  },
  computed: {
    name: function () {
      return this.$store.getters['devices/getNameByDeviceId'](this.currentDeviceId)
    },
    deviceIds() {
      return [this.currentDeviceId]
    },
    deviceModel() {
      return this.$store.getters['devices/getModelByDeviceId'](this.currentDeviceId)
    },
    deviceType: function () {
      const modelKey = this.$store.getters['devicemodels/getModelKey'](
        this.deviceModel
      )
      return deviceModelDisplay(modelKey, this.$t.bind(this))
    },
    serial() {
      return this.$store.getters['devices/getSerialByDeviceId'](this.currentDeviceId) || ""
    },
    isDeviceConnected() {
      return this.$store.getters['devices/getIsConnectedByDeviceId'](
        this.currentDeviceId
      )
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    parameterChange(newValue) {
      this.selectedParameter = newValue
    },
    ...mapActions('devices', ['getLatestTelemetryAndUpdate', 'getDeviceDetailsAndUpdate']),
    async refresh() {
      if (this.currentDeviceId) {
        this.isLoading = true
        // populate vuex store with data to display in child components
        await this.getLatestTelemetryAndUpdate(this.currentDeviceId)
        await this.getDeviceDetailsAndUpdate(this.currentDeviceId)
        await this.setLatestStudyData()
        this.isLoading = false
      }
    },
    async setLatestStudyData() {
      this.latestDeviceStudy = await getLatestStudy(this.currentDeviceId)
    },
    async setIsNameOverflowing() {
      await Vue.nextTick()
      this.isNameOverflowing = isChildOverflowing(this.$refs.deviceName)
    },
  },
}
</script>

<style lang="scss" scoped>
  .v-dialog {
    .v-card {
      padding: 0 !important;
    }
    .v-card__title {
      padding: 0;
      font-size: 15px;
      .current-readings-title {
        margin-right: 4px;
        margin-left: 4px;
      }
    }
  }

  .widget-header-container,
  .widget-footer-container {
    flex-wrap: nowrap;
    max-width: 100%;
    min-height: 42px;
    padding: 0 4px;
    font-size: 15px;
  }

  .widget-footer-container {
    display: flex;
    justify-content: space-between;
    padding: 6px 12px !important;
  }

  .widget-header-container {
    .widget-actions {
      margin-left: 0px;
      margin-right: 8px;
    }
    .widget-title-container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-bottom: 4px;
      .widget-title {
        font-size: 16px;
        margin-right: 4px;
        margin-left: 4px;
        height: 22px;
        line-height: 26px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .widget-subtitle {
        margin-left: 4px;
        height: 12px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .widget-left-toolbar,
    .widget-right-toolbar {
      white-space: nowrap;
    }

    .fullscreen-button {
      padding: 0;
    }
  }

  .readings-dialog-content-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 14px;
    min-height: 400px;
  }

  .disconnected-device-theme {
    border: 1px solid red;

    .widget-header-container {
      background-color: red;
      color: white !important;

      button,
      span,
      ::v-deep .v-icon {
        color: white !important;
      }
    }
  }
</style>
